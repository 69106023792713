<template>
    <div class="component-multiple-select">
        <div class="multiple-select">
            <div class="form-label label">
                {{ label }}
                <span v-if="isRequired" class="attribute-required">*</span>
            </div>

            <VueMultiselect
                v-model="selectedCopy"
                :mode="multiple ? 'tags' : 'single'"
                :options="selectOptions"
                :searchable="true"
                valueProp="id"
                trackBy="title"
                label="title"
                :placeholder="placeholder"
                :locale="$i18n.locale"

                :noOptionsText="$tc('noOptions', 1)"
                :noResultsText="$tc('noResults', 1)"
            ></VueMultiselect>
            <!-- searchFilter - for its own search function -->
            <div class="error-text">{{ errorText }}</div>
        </div>
    </div>
</template>

<script>
import VueMultiselect from '@vueform/multiselect';
import { mapGetters } from "vuex";

export default {
    name: "MultipleSelect",
    components: {
        VueMultiselect,
    },
    props: [ 'selected', 'optionsKey', 'label', 'placeholder', 'multiple', 'errorText', 'isRequired' ],
    computed: {
        ...mapGetters({
            getPositionParametersByKey: "content/getPositionParametersByKey",
        }),
        selectedCopy: {
            get() {
                return this.selected
            },
            set(val) {
                this.$emit('update:selected', val);
            }
        },
        selectOptions() {
            return this.getPositionParametersByKey(this.optionsKey) || []
        }
    }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
.component-multiple-select::v-deep(.multiple-select) {
    .label {
        width: 100%;
        text-align: left;
        color: $inputLabelColor;
    }

    .multiselect {
        min-height: 44px;
        border-color: $inputBorderColor;
        --ms-radius: 6px;

        &.is-active {
            box-shadow: none;
        }

        .multiselect-wrapper {
            min-height: inherit;

            .multiselect-placeholder {
                font-style: italic;
                font-size: $inputPlaceholderSize;
                color: $inputPlaceholderColor;
                opacity: $inputPlaceholderOpacity;
            }

            .multiselect-tags {
                .multiselect-tag {
                    background: $primary;
                    font-weight: 500;
                }

                // search input
                .multiselect-tags-search-wrapper {
                    //
                }
            }

            // clear button
            .multiselect-clear {
                z-index: 1;

                &-icon {
                    background-color: $danger;
                }
            }

            // dropdown button
            .multiselect-caret {
                background-color: $primary;
                z-index: 1;
                cursor: pointer;
            }
        }

        .multiselect-dropdown {
            .multiselect-options {
                .multiselect-option {
                    // hover on option
                    &.is-pointed {
                        //
                    }

                    // selected option
                    &.is-selected {
                        background: $primary;
                    }
                }
            }
        }
    }
}
</style>
